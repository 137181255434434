
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code7 } from "./data";

let id = 0;

export default defineComponent({
  name: "dynamic-loading",
  data() {
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const nodes = Array.from({ length: level + 1 }).map(item => ({
              value: ++id,
              label: `Option - ${id}`,
              leaf: level >= 2
            }));
            // Invoke `resolve` callback to return the child nodes data and indicate the loading is finished.
            resolve(nodes);
          }, 1000);
        }
      }
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code7
    };
  }
});
